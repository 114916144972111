import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MaterialModule } from '../../components/material/material.module';
import { BootstrapModule } from '../../components/bootstrap/bootstrap.module';
import { AssetsService } from 'src/app/shared/services/assets.service';
import { JobsService } from '../jobs/jobs-service/jobs.service';
import { UsersContainerService } from './services/users.container.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { JobsContainerModule } from '../jobs/jobs.container.module';
import { MAT_DATE_FORMATS } from '@angular/material/core';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    BootstrapModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    JobsContainerModule,
  ],
  exports: [],
  providers: [
    JobsService,
    AssetsService,
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: {
          dateInput: 'DD/MM/YYYY',
        },
        display: {
          dateInput: 'DD/MM/YYYY',
          monthYearLabel: 'MMMM YYYY',
          dateA11yLabel: 'LL',
          monthYearA11yLabel: 'MMMM YYYY',
        },
      },
    },
  ],
})
export class UsersContainerModule {}
